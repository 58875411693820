const http = require("./http-common.js").default;
import store from "@/store";

export function get(path, config) {
  return http
    .get(path, config)
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

export function post(path, data, config) {
  return http
    .post(path, data, config)
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

export function getFile(path) {
  var config = {
    responseType: "blob",
  };
  return (
    http
      .get(path, config)
      // for files we need to return the actual response and not the response.data
      .then((response) => response)
      .catch((ex) => onError(ex))
  );
}

export function put(path, data) {
  return http
    .put(path, data)
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

export function postForm(url, data) {
  var config = {
    headers: {
      "Content-type": "multipart/form-data",
    },
  };
  return post(url, data, config);
}

export function remove(path, data) {
  return http
    .delete(path, { data: data })
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

const onError = (ex) => {
  if (ex.response.status === 403) {
    store.commit("clear");
  }
  return new Promise(() => {
    throw ex.response.data;
  });
};
